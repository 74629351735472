import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IVideoButtonComponentProps } from './model';
import 'components/common/Video/VideoButton/VideoButton.scss';

const VideoButton: FC<IVideoButtonComponentProps> = ({ btnPlayAriaLabel, btnPlay, onClick }) => {
  return (
    <button
      {...(btnPlayAriaLabel ? { 'aria-label': btnPlayAriaLabel } : {})}
      {...(btnPlayAriaLabel ? { title: btnPlayAriaLabel } : {})}
      className="video-button"
      type="button"
      data-test="VideoButton"
      onClick={onClick}
    >
      {typeof btnPlay === 'string' ? <DangerouslySetInnerHtml html={btnPlay} /> : null}
    </button>
  );
};

export default VideoButton;
